






















































import { Component, Vue } from "vue-property-decorator";
import { Database } from "@/types/Database";
import { loadDatabases } from "@/api/databases";
import { convertMoney } from "@/common/helper";

@Component({
  components: {}
})
export default class DatabasePage extends Vue {
  database: Database[] = [];
  loading = false;

  convertMoney = convertMoney;

  mounted() {
    this.loadDatabase();
  }

  async loadDatabase() {
    this.loading = true;
    const { data } = await loadDatabases();
    this.database = data;
    this.loading = false;
  }

  colorTag = (status: boolean) => (status ? "green" : "red");
}
