import ApiService from "@/api/api.service";
import { AxiosPromise } from "axios";
import { Database } from "@/types/Database";

export const loadDatabases = (): AxiosPromise<Database[]> => {
  return ApiService.get("api/admin/databases");
};

export const loadDatabase = (id: number): AxiosPromise<Database> => {
  return ApiService.get(`api/admin/databases/${id}`);
};

export const createDatabase = (database: Database): AxiosPromise<Database> => {
  return ApiService.post("api/admin/databases", database);
};

export const updateDatabase = (id: number, database: Database): AxiosPromise<Database> => {
  return ApiService.put(`api/admin/databases/${id}`, database);
};
